const formFields = {
  signUp: {
    "custom:artistName": {
      labelHidden: true,
      label: "Artist Name",
      placeholder: "Artist Name -- stored as fizzgen creator",
      isRequired: true,
    },
  },
};

export default formFields;
