const filtersInUse = {
  "8bit": "8bit",
  aeon: "aeon",
  blues: "blues",
  "alien xray": "cool_twilight",
  coral: "coral",
  cosmic: "cosmic",
  crimson: "crimson",
  burrito: "diagonal_lines",
  "offset blue": "extra_offset_blue",
  "offset green": "extra_offset_green",
  "offset red": "extreme_offset_red",
  lettuce: "green_diagonal_lines",
  yearbook: "green_min_noise",
  "green specks": "green_specks",
  greyscale: "greyscale",
  haze: "haze",
  "whizzpopper flurry": "incbrightness2",
  invert: "invert",
  neue: "neue",
  perfume: "perfume",
  retroviolet: "retroviolet",
  ryo: "ryo",
  solange: "solange",
  specks: "specks",
  vintage: "vintage",
  "mountain dewed": "yellow_casino",
};

export default filtersInUse;
